import axios from './request'

import { Auth } from 'aws-amplify';

export default class Api {

  static async reportUser(l, userId, startDate, endDate) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    let url = `/users/${userId}/reports`
    let searchParam = [];
    searchParam.push(["startDate", startDate]);
    searchParam.push(["endDate", endDate]);
    if (searchParam.length > 0) {
      searchParam.forEach((item, index) => {
        url += index === 0 ? '?' : '&';
        url += `${item[0]}=${item[1]}`
      });
    }
    return axios.get(url, {
        headers
      })
      .then(response => response.data)
      .then(data => {
        return data;
      });
  }



  static renderTicketURL(l, ticketId, format="html") {
    return `/tickets/${ticketId}/render?format=${format}&paper=80`
  }

  static async renderTicket(l, ticketId, format="html") {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.get(this.renderTicketURL(l, ticketId, format), {
        headers
      })
      .then(response => response.data)
      .then(data => {
        return data;
      });
  }

  static async buySeries(l, matchId, amount) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.post(`/matches/${matchId}/buySeries`, {
        amount
      }, {
        headers
      })
      .then(response => response.data)
      .then(data => {
        window.pagesense.push(['trackEvent', 'cartela_vendida']);
        return data;
      });
  }

  static async getMyCards(l, matchId) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.get(`/matches/${matchId}/myCards`, {
        headers
      })
      .then(response => response.data);
  }

  static async buyCards(l, matchId, amount) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.post(`/matches/${matchId}/buyCards`, {
        amount
      }, {
        headers
      })
      .then(response => response.data)
      .then( data => {
        window.pagesense.push(['trackEvent', 'cartela_vendida']);
        return data;
      })
  }

  static async me(l) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.get('/me', {
        headers
      })
      .then(response => response.data);
  }

  static async updateMe(l, name) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.put('/me', { name }, {
        headers
      })
      .then(response => response.data);
  }

  static async fetchAllMatches(l, page, perPage, date, status) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };

    let searchParam = [];
    if (page) {
      searchParam.push(["page", page + 1]);
    }
    if (perPage) {
      searchParam.push(["perPage", perPage]);
    }
    if (date) {
      searchParam.push(["date", date]);
    }
    if (status) {
      searchParam.push(["status", status]);
    }

    let url = `/matches`;
    if (searchParam.length > 0) {
      searchParam.forEach((item, index) => {
        url += index === 0 ? '?' : '&';
        url += `${item[0]}=${item[1]}`
      });
    }

    return axios.get(url, {
        headers
      })
      .then(response => {
        return {
          rows: response.data,
          count: parseInt(response.headers['x-total-count'])
        }
      });
  }

  static async fetchAllCharges(l, page, perPage) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    let url = `/charges?`
    if (page !== undefined) {
      url += `page=${page+1}&`
    }
    if (perPage !== undefined) {
      url += `perPage=${perPage}&`
    }
    return axios.get(url, {
        headers
      })
      .then(response => {
        return {
          rows: response.data,
          count: parseInt(response.headers['x-total-count'])
        }
      });
  }

  static async fetchAllMatchesNotFinished(l) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    let url = `/matches/not_finished?`
    return axios.get(url, {
        headers
      })
      .then(response => {
        return response.data
      });
  }

  static async fetchMatchesAds(l) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    let url = `/matches/ads?`
    return axios.get(url, {
        headers
      })
      .then(response => {
        return response.data
      });
  }

  static async fetchMatchById(l, matchId) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.get(`/matches/${matchId}`, {
        headers
      })
      .then(response => response.data);
  }

  static async fetchMatchInPlay(l) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.get(`/matches/inPlay`, {
        headers
      })
      .then(response => response.data);
  }

  static async openSell(l, match) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const {
      id
    } = match
    const headers = {
      Authorization: access_token
    };
    return axios.post(`/matches/${id}/openSell`, {}, {
        headers
      })
      .then(response => response.data)
  }

  static async saveCharge(l, amount, phoneNumber) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.post(`/charges`, { amount, phoneNumber }, {
        headers
      })
      .then(response => response.data)
  }

  static async closeSell(l, match) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const {
      id
    } = match
    const headers = {
      Authorization: access_token
    };
    return axios.post(`/matches/${id}/closeSell`, {}, {
        headers
      })
      .then(response => response.data);
  }

  static async openMatch(l, {
    id
  }) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.post(`/matches/${id}/startMatch`, {}, {
        headers
      })
      .then(response => response.data);
  }

  static async closeMatch(l, {
    id
  }) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.post(`/matches/${id}/closeMatch`, {}, {
        headers
      })
      .then(response => response.data);
  }

  static async finishMatch(l, {
    id
  }) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.post(`/matches/${id}/finishMatch`, {}, {
        headers
      })
      .then(response => response.data);
  }

  static async sortBall(l, {
    id
  }, ball) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.post(`/matches/${id}/sortBall`, {
        ball: parseInt(ball)
      }, {
        headers
      })
      .then(response => response.data);
  }

  static async updateMatch(l, matchId, match) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.put(`/matches/${matchId}`, match, {
        headers
      })
      .then(response => response.data);
  }

  static async updateMatchAD(l, matchId, toAd) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.put(`/matches/${matchId}/changeAd`, { toAd }, {
        headers
      })
      .then(response => response.data);
  }

  static async createMatch(l, match) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.post(`/matches`, match, {
        headers
      })
      .then(response => response.data);
  }

  static async destroyMatch(l, matchId) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    return axios.delete(`/matches/${matchId}`, {
        headers
      })
      .then(response => response.data);
  }

  static async listUsers(l, page, perPage, query, id) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };

    let searchParam = [];

    //let url = `/users`
    //se o valor do page não for definido ele ignora o if
    if (page !== undefined) {
      //url += `?page=${page+1}`
      //adicionando a tupla key valor a variável searchParam.
      searchParam.push(["page", page + 1]);
    }
    if (perPage !== undefined) {
      //url += `&perPage=${perPage}`
      //adicionando a tupla key valor a variável searchParam.
      searchParam.push(["perPage", perPage]);
    }
    if (query !== undefined) {
      //url += `&query=${query}`
      //adicionando a tupla key valor a variável searchParam.
      searchParam.push(["query", query]);
    }
    if (id !== undefined) {
      //url += `&query=${query}`
      //adicionando a tupla key valor a variável searchParam.
      searchParam.push(["id", id]);
    }

    let url = `/users`;
    if (searchParam.length > 0) {
      // "/users?page=1&perPage=8&query=20&idNumber=2"
      // E Lógico separa os parametros.
      searchParam.forEach((item, index) => {
        url += index === 0 ? '?' : '&';
        url += `${item[0]}=${item[1]}`
      });
    }

    return axios.get(url, {
        headers
      })
      .then(response => {
        return {
          rows: response.data,
          count: parseInt(response.headers['x-total-count'])
        }
      });
  }

  static async listTransactions(l, userId, page, perPage) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    let url = `/users/${userId}/transactions?`
    if (page !== undefined) {
      url += `page=${page+1}&`
    }
    if (perPage !== undefined) {
      url += `perPage=${perPage}&`
    }
    return axios.get(url, {
        headers
      })
      .then(response => {
        return {
          rows: response.data,
          count: parseInt(response.headers['x-total-count'])
        }
      });
  }

  static async updateUser(l, userId, data) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    let url = `/users/${userId}`
    return axios.put(url, data, {
        headers
      })
      .then(response => response.data);
  }

  static async removeCreditSeller(l, userId, amount) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    let url = `/users/${userId}/removeCreditSeller`
    return axios.post(url, {
        amount
      }, {
        headers
      })
      .then(response => response.data);
  }

  static async removeCredit(l, userId, amount) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    let url = `/users/${userId}/removeCredit`
    return axios.post(url, {
        amount
      }, {
        headers
      })
      .then(response => response.data);
  }

  static async addCredit(l, userId, amount) {
    const response = await Auth.currentSession()
    const access_token = response.idToken.jwtToken
    const headers = {
      Authorization: access_token
    };
    let url = `/users/${userId}/addCredit`
    return axios.post(url, {
        amount
      }, {
        headers
      })
      .then(response => response.data);
  }
}