import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';

export default function LoadingButtonComponent({ loading, disabled, children, ...props }) {
  const classes = useStyles();

  return (
    <span className={classes.wrapper}>
      <Button {...props} disabled={loading || disabled}>
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          className={classes.progress}
        />
      )}
    </span>
  );
};

LoadingButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
