import axios from "axios";

const requester = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_API,
  crossDomain: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json' 
  }
});

export default requester