import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import useStyles from './styles';

export default function CenterComponent({ children, force, className }) {
  const classes = useStyles();

  return (
    <div className={classNames(
      classes.root,
      force && classes.force,
      className,
    )}>
      {children}
    </div>
  );
}

CenterComponent.propTypes = {
  children: PropTypes.node.isRequired,
  force: PropTypes.bool,
  className: PropTypes.string,
};
