export class Printer {

  static rawbtPrinter(url) {
    window.open(`rawbt:url:base64,${btoa(url)}`, "Cartela", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=330,height=500")
  }

  static localPrinter(data) {
    const newWin = window.open('', "Cartela", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=965,height=780")
    const { document: doc, print } = newWin
    doc.write(data)
    setTimeout(function(){
      print()
      }, 1000)
      // close()
  }
}