import React, { Component, useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import Zendesk from "react-zendesk";
import useAuth from '../hooks/useAuth';
import { ZendeskAPI } from "react-zendesk";

const ZenDeskComponent = ({ component }) => {
  const { user } = useAuth();
  const [loaded, setIsLoaded] = useState(false)

  useEffect(function(){
    if(user && loaded){
      const email = `customer_${user.id}@palpitenrede.com`
      ZendeskAPI('webWidget', 'identify', { name: user.name, email });
      ZendeskAPI('webWidget', 'hide')
      ZendeskAPI('webWidget:on', 'close', () => { ZendeskAPI('webWidget', 'hide') })
    }
    if(!user && loaded) {
      ZendeskAPI('webWidget', 'logout')
    }
  }, [user, loaded])

  return <div>
    { process.env.REACT_APP_ZE && <Zendesk zendeskKey={process.env.REACT_APP_ZE} onLoaded={() => setIsLoaded(true)} /> }
    {component}
  </div>
}

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = class extends Component {
    state = {
      loaded: false
    }

    componentDidMount() {
      const {
        location: { pathname: page }
      } = this.props;
      trackPage(page);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      const {
        location: { pathname: currentPage }
      } = this.props;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    loadState = (evt) => {
      this.setState({ loaded: true })
    }

    render() {
      return <ZenDeskComponent component={<WrappedComponent {...this.props} />} />
    }
  };

  return HOC;
}