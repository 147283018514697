import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.REACT_APP_IQSALES_KEY){
  var $zoho = window.$zoho || {};
  $zoho.salesiq = $zoho.salesiq ||{
    widgetcode:process.env.REACT_APP_IQSALES_KEY,
    values:{},
    ready:function(){}
  };
  const d = window.document;
  const s = d.createElement("script");
  s.type="text/javascript";
  s.id="zsiqscript";
  s.defer=true;
  s.src="https://salesiq.zoho.com/widget";
  const t = d.getElementsByTagName("script")[0];
  t.parentNode.insertBefore(s,t);
  d.write("<div id='zsiqwidget'></div>");
}