import { lazy } from 'react';

export const signin = '/entrar';
export const confirmCode = '/confirmacao';
export const signup = '/cadastro';
export const recovery = '/recuperar-senha';
export const recoverySubmit = '/altere-senha';
export const home = '/';
export const charges = '/recargas';
export const reports = '/relatorio';
export const profile = '/perfil';

const routes = [
  {
    key: 'signin',
    path: signin,
    component: lazy(() => import('./auth/signIn')),
    private: false,
  },
  {
    key: 'confirmCode',
    path: confirmCode,
    component: lazy(() => import('./auth/confirm')),
    private: false,
  },
  {
    key: 'signup',
    path: signup,
    component: lazy(() => import('./auth/signUp')),
    private: false,
  },
  {
    key: 'recovery',
    path: recovery,
    component: lazy(() => import('./auth/recovery')),
    private: false,
  },
  {
    key: 'recoverySubmit',
    path: recoverySubmit,
    component: lazy(() => import('./auth/recovery_submit')),
    private: false,
  },
  {
    key: 'root',
    path: home,
    component: lazy(() => import('./match')),
    private: true,
  },
  {
    key: 'charges',
    path: charges,
    component: lazy(() => import('./charges')),
    private: true,
  },
  {
    key: 'reports',
    path: reports,
    component: lazy(() => import('./reports')),
    private: true,
  },
  {
    key: 'profile',
    path: profile,
    component: lazy(() => import('./profile')),
    private: true,
  },
];

export default routes;