import React, { useState, useEffect, useCallback, useContext, createContext, useMemo } from 'react';
import Auth from '../config/amplify.js';
import { Authenticator } from 'aws-amplify-react';
import Loading from '../components/Loading';
import API from '../api'
import get from 'lodash/get'
import { confirmCode as confirmCodePath, home as homePath, recoverySubmit as recoverySubmitPath, signin as signinPath } from '../pages/routes';
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';
import useWs from './useWs'

const authContext = createContext();
const i18n = message => {
  if (/incorrect.*username.*password/i.test(message)) {
    return 'Telefone ou senha incorretos';
  }
  return message;
};

const AmplifyAuth = props => (
  <Authenticator hideDefault>
    <AuthProvider {...props} />
  </Authenticator>
);
const useAuth = () => useContext(authContext);

export { AmplifyAuth as AuthProvider };
export default useAuth;

function AuthProvider({ children, authData, authState }) {
  const auth = useAuthProvider(authState, authData);
  return (
    <authContext.Provider value={auth}>
      {!auth.ready ? <Loading forceCenter />  : children}
    </authContext.Provider>
  );
}

function useAuthProvider(authState, authData) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(authData);
  const [user, setUser] = useState();
  const { subscribe } = useWs()

  const token = useMemo(() => get(data, 'signInUserSession.idToken.jwtToken'), [data]);

  useEffect(() => {
    setData(authData);
  }, [authData]);

  useEffect(() => {
    setReady(authState !== 'loading' && (!authData || user));
  }, [authState, authData, user]);

  useEffect(() => {
    if(!user) return
    const { cancel } = subscribe(`user-${user.id}`, (channel) => {
      channel.unbind()
      channel.bind('walletChange', data => {
        setUser(data)
      })
    })
    return cancel
  }, [user, subscribe])

  const me = useCallback(async () => {
    let _user = null;
    if (token) {
      try{
        const _me = await API.me(token);
        _user = { ...data.attributes, ..._me };
        ReactGA.set({ userId: _user.id, role: _user.roleName })
      }catch(e) {
        console.error(e)
        await Auth.signOut();
        history.push({ pathname: signinPath })
      }
    }
    setUser(_user);
    setIsLoading(false);
  }, [token, data, history]);

  const changeWallet = useCallback(function(wallet){
    setIsLoading(true);
    if(user.Wallet.id === wallet.id ) {
      user.Wallet.amount = wallet.amount
    }    
    setIsLoading(false);
    setUser(user)
  }, [user])

  useEffect(() => {
    setIsLoading(true);
    me();
  }, [me]);

  const execute = useCallback(async (method, callback) => {
    setIsLoading(true);
    setError(prev => ({ ...prev, message: null, [method]: null }));
    let data;
    try {
      data = await callback();
    } catch (err) {
      data = i18n(err.message);
      setError(prev => ({ ...prev, message: data, [method]: data }));
    } finally {
      setIsLoading(false);
    }
    return data;
  }, []);

  const signIn = useCallback(async ({ username, password }) => (
    execute('signIn', async () => {
      try{
        const resp = await Auth.signIn(username, password);
        setData(resp)
        return history.push({ pathname: homePath })
      }catch(e){
        if(e.code === "UserNotConfirmedException")
          return history.push({ pathname: confirmCodePath, search: `?username=${username}` })
        throw e
      }
    })
  ), [execute, history]);

  const signUp = useCallback(async ({ name, phone_number, password, birthdate, promocode }) => (
    execute('signUp', async () => {
      try{
        await Auth.signUp({ username: phone_number, password, attributes: { name, phone_number, birthdate, 'custom:promocode': promocode }});
        window.pagesense.push(['trackEvent', 'cadastro']);
        return signIn({ username: phone_number, password })
        // return history.push({ pathname: confirmCodePath, search: `?username=${_user.user.username}` })
      }catch(e) {
        if(e.code === 'UsernameExistsException') {
          throw new Error("Já existe um cadastrado com esse telefone. Faça o login ou entre em contato com o suporte.")
        }
        throw new Error("Verifique os campos e tente novamente.")
      }
    })
  ), [execute, signIn]);

  const signOut = useCallback(async () => (
    execute('signOut', async () => {
      await Auth.signOut();
      setUser(null);
    })
  ), [execute]);

  const resendCode = useCallback(async ({ username }) => {
    setIsLoading(true)
    await Auth.resendSignUp(username)
    setUser(null);
    setIsLoading(false)
  }, []);

  const confirmCode = useCallback(async ({ username, code, password }) => (
    execute('confirmCode', async () => {
      await Auth.confirmSignUp(username, code)
      window.pagesense.push(['trackEvent', 'cadastro']);
      return signIn({ username, code, password })
    })
  ), [execute, signIn]);

  const recoveryPassword = useCallback(async ({ username }) => (
    execute('recoveryPassword', async () => {
      await Auth.forgotPassword(username)
      return history.push({ pathname: recoverySubmitPath, search: `?username=${username}` })
    })
  ), [execute, history]);

  const changePassword = useCallback(async ({ username, code, password }) => (
    execute('changePassword', async () => {
      await Auth.forgotPasswordSubmit(username, code, password)
      return history.push({ pathname: signinPath, search: `?username=${username}` })
    })
  ), [execute, history]);

  const updateMe = useCallback(async ({ name }) => {
    setIsLoading(true)
    try{
      await API.updateMe(token, name)
      setUser({ ...user, name });
    }catch(e){
      setError(e)
    }finally{
      setIsLoading(false)
    }
  }, [token, user])

  return {
    ready,
    isLoading,
    changePassword,
    confirmCode,
    resendCode,
    recoveryPassword,
    token,
    error,
    errorMessage: error.message,
    user,
    signUp,
    signIn,
    signOut,
    changeWallet,
    updateMe
  };
}
