import React, { useState, useContext, createContext, useCallback } from 'react';

import useAuth from './useAuth';
import API from '../api'
import { Printer } from '../services/print'
import { get, set } from 'lodash'

import ReactGA from 'react-ga';

const matchBoardContext = createContext();

const useMatchBoard = () => useContext(matchBoardContext);

export default useMatchBoard;

export function MatchBoardProvider({ children }) {
  const state = useMatchBoardProvider();
  return (
    <matchBoardContext.Provider value={state}>
      {children}
    </matchBoardContext.Provider>
  );
}

function useMatchBoardProvider() {
  const [isFetching, setIsFetching] = useState(false);
  const [matches, setMatches] = useState([]);
  const [cards, setCards] = useState([]);
  const [match, setMatch] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const { token, user } = useAuth();
  
  const printTicket = useCallback(async function(ticketId) {
    if(user.printerType === 'none') return
    if( user.printerType !== `local` ) {
      const url = API.renderTicketURL(token, ticketId)
      Printer.rawbtPrinter(process.env.REACT_APP_ENDPOINT_API + `${url}`)
    } else {
      const data = await API.renderTicket(token, ticketId)
      Printer.localPrinter(data, user.printerType)
    }
  }, [token, user])

  const buyCards = useCallback(async function(match, amount){
    setIsFetching(true)
    try{
      const ticket = await API.buyCards(token, match.id, amount)
      ReactGA.event({
        category: 'Card',
        action: 'card buyed',
        value: ticket.amount
      });
      await printTicket(ticket.id)
      setIsFetching(false)
    }catch(e){
      setErrorMessage(e.response.data.message || e.response.data)
      setIsFetching(false)
      return Promise.reject(e.response.data.message || e.response.data)
    }
  }, [token, printTicket])

  const buySeries = useCallback(async function(match, amount){
    setIsFetching(true)
    try{
      const ticket = await API.buySeries(token, match.id, amount)
      ReactGA.event({
        category: 'Card',
        action: 'series buyed',
        value: ticket.amount
      });
      await printTicket(ticket.id)
      setIsFetching(false)
    }catch(e){
      setErrorMessage(e.response.data.message || e.response.data)
      setIsFetching(false)
      return Promise.reject(e.response.data.message || e.response.data)
    }
  }, [token, printTicket])

  const fetchMatchById = useCallback(async id => {
    if (!token) {
      return;
    }
    setIsFetching(true);
    setErrorMessage(null);
    try {
      const response = await API.fetchMatchById(token, id);
      setMatch(response);
    } catch (err) {
      const message = 'Partida não encontrada';
      setErrorMessage(message);
    } finally {
      setIsFetching(false);
    }
  }, [token]);

  const fetchMatchInPlay = useCallback(async () => {
    if (!token) { return; }
    setIsFetching(true);
    setErrorMessage(null);
    try {
      const response = await API.fetchMatchInPlay(token);
      setMatch(response);
    } catch (err) {
      const message = 'Nenhuma partida em execução';
      setErrorMessage(message);
      setMatch(null);
    } finally {
      setIsFetching(false);
    }
  }, [token]);

  const fetchAllNotFinished = useCallback(async (page, perPage) => {
    if (!token) {
      return;
    }
    setIsFetching(true);
    setErrorMessage(null);
    try {
      const _matches = await API.fetchAllMatchesNotFinished(token);
      setMatches(_matches);
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsFetching(false);
    }
  }, [token]);

  const getMatchIndex = (matches, match) => {
    return matches.indexOf( matches.find(i => i.id === match.id) )
  }

  const bindEvents = useCallback(async (channel) => {
    channel.unbind();
    channel.bind('play', match => fetchMatchById(match.id))
    channel.bind('close', () => setMatch(null) )
    channel.bind('finish', (_match) => {
      const index = getMatchIndex(matches, _match)
      if(index>=0) {
        matches[index].status = "finished"
        setMatches([...matches])
      }
      setMatch(null)
    })
    channel.bind('ball', (_match) => {
      set(match, 'sortedBalls', _match.sortedBalls)
      set(match, 'BestCards', _match.BestCards)
      set(match, 'MatchAwards', _match.MatchAwards)
      setMatch({ ...match })
    })
    channel.bind('openSell', (_match) => {
      const index = getMatchIndex(matches, _match)
      if(index>=0) {
        matches[index].sellOpen = true
        setMatches([...matches])
      }
    })
    channel.bind('closeSell', (_match) => {
      const index = getMatchIndex(matches, _match)
      if(index>=0) {
        matches[index].sellOpen = false
        setMatches([...matches])
      }
    })
  }, [fetchMatchById, matches, match])

  const fetchMatchMyCards = useCallback(async ({ id }) => {
    setIsFetching(true)
    setErrorMessage(null)
    try{
      setCards(await API.getMyCards(token, id))
    }catch(e){
      setErrorMessage( get(e.response, 'data.message') || e.message )
    }
    setIsFetching(false)
  }, [token])

  return {
    isFetching,
    buyCards,
    buySeries,
    cards,
    errorMessage,
    matches,
    match,
    fetchAllNotFinished,
    fetchMatchById,
    fetchMatchMyCards,
    bindEvents,
    fetchMatchInPlay,
    printTicket
  };
}