import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './hooks/useAuth';
import { WsProvider } from './hooks/useWs';
import { MatchBoardProvider } from './hooks/useMatchBoard';
import Pusher from 'pusher-js'
import Pages from './pages';
import "moment/locale/pt-br";
import './App.css'

moment.locale("pt-br");

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, { cluster: 'mt1' });

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d64527',
    },
    secondary: {
      main: '#009688',
    },
    blueDark: {
      main: '#333648'
    }
  },
  shape: {
    borderRadius: 10,
    min: {
      borderRadius: 10,
    }
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        paddingLeft: 30,
        paddingRight: 30,
      },
      adornedStart: {
        paddingLeft: 18,
      },
      adornedEnd: {
        paddingRight: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10
      }
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: 9,
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router>
            <WsProvider websocket={pusher}>
              <AuthProvider>
                <MatchBoardProvider>
                  <Pages />
                </MatchBoardProvider>
              </AuthProvider>
            </WsProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
