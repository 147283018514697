import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Center from '../../components/Center';
import { Link } from 'react-router-dom';
import { home } from '../routes';
import withTraker from '../withTracker'

export function NotFoundPage() {
  return (
    <Box bgcolor="info.light" color="info.contrastText">
      <Center force>
        <Box>
          <Grid container spacing={4} alignItems="center">
            <Grid item><Typography variant="h1">404</Typography></Grid>
            <Grid item><Typography component="h2" variant="h4">página não<br />encontrada</Typography></Grid>
          </Grid>
          <Box textAlign="center" mt={2}>
            <Typography>
              <MuiLink component={Link} to={home}>
                voltar para o início
              </MuiLink>
            </Typography>
          </Box>
        </Box>
      </Center>
    </Box>
  );
}

export default withTraker(NotFoundPage)