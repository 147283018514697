import Auth from '@aws-amplify/auth';

Auth.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_WEBCLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,
  }
});

export default Auth;