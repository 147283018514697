import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import routes, { signin, home } from './routes';
import NotFoundPage from './notFound';
import Loading from '../components/Loading'

const renderRoute = ({
  private: isPrivate,
  user,
  component: Component,
  ...config
}) => (
  <Route exact {...config} render={props => {
    if (isPrivate && !user) {
      return <Redirect to={{ pathname: signin }} />;
    }
    if (isPrivate === false && user) {
      return <Redirect to={{ pathname: home }} />;
    }
    return <Component {...props} />;
  }} />
);

export default function Pages() {
  const { user } = useAuth();
  return (
    <Suspense fallback={<Loading forceCenter />}>
      <Switch>
        {routes.map(route => renderRoute({ ...route, user }))}
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
}