import React, { useContext, createContext, useCallback } from 'react';
// import useAuth from './useAuth';

const wsContext = createContext();
const useWs = () => useContext(wsContext);

export default useWs;

export function WsProvider({ websocket, children }) {
  const state = useWsProvider({ websocket });
    return (
    <wsContext.Provider value={state}>
      {children}
    </wsContext.Provider>
  );
}

function useWsProvider({ websocket }) {
  // const { token, user } = useAuth()
  const subscribe = useCallback(async (action, bindEvents) => {
    const _channel = websocket.subscribe(action)
    bindEvents(_channel)
  }, [websocket])

  const allChannels = () => {
    return websocket.allChannels()
  }

  return {
    subscribe,
    allChannels
  };
}